define("discourse/plugins/discourse-wechat-article/discourse/discourse-markdown/whitelist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    if (!helper.markdownIt) {
      return;
    }
    helper.whiteList(["p[data-*]"]);
    helper.whiteList(["img[data-*]"]);
    helper.whiteList(["h2[data-*]"]);
    helper.whiteList(["h3[data-*]"]);
    helper.whiteList(["a[data-*]"]);
  }
});